/**
 * Main entrypoint of the application.
 * Here is initialized the ControllerDispatcher and loaded the main resources shared by all pages
 */

import 'intersection-observer';
import 'core-js/stable/set';
import 'core-js/stable/promise';
import 'core-js/stable/symbol';
import 'core-js/stable/object';
import 'core-js/stable/array';

// import '../fonts/**';
import '../stylesheets/main.scss';

import './modules/configurations/public-path';

// Load the controller dinamically based on the data attribute of the body
import ControllerDispatcher from './modules/utils/ControllerDispatcher';

let ctrDispatcher = new ControllerDispatcher();
ctrDispatcher.dispatchToController();
